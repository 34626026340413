import { Product, StoreLayout, StoreSearch } from "@lgastler-ba/ui";
import React, { useEffect, useState } from "react";
import { mapCommerceProduct, removeEmpty } from "@lgastler-ba/shopify";
import { StringParam, useQueryParams } from "use-query-params";

export default function SearchPage() {
  const [products, setProducts] = useState<Array<Product>>([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    category: StringParam,
  });

  const queryParams = new URLSearchParams(
    removeEmpty({
      q: query.q ?? undefined,
      category: query.category,
    })
  );

  useEffect(() => {
    const searchForm = document.getElementById("search-form");
    searchForm?.addEventListener("submit", (e) => {
      e.preventDefault();
      const formElement: HTMLFormElement | null = e.target as HTMLFormElement;
      if (formElement) {
        const formData = new FormData(formElement);
        setQuery({ q: formData.get("q") as string, category: undefined });
      }
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(
      `${
        process.env.GATSBY_TWEET_API_ENDPOINT
      }/search?${queryParams.toString()}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.GATSBY_TWEET_SECRET ?? "",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setProducts(mapCommerceProduct(data.products));
        setLoading(false);
      });
  }, [query.q]);

  return (
    <StoreLayout>
      <StoreSearch
        term={query.q as string}
        products={products}
        loading={loading}
      />
    </StoreLayout>
  );
}
